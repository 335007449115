import React, {Dispatch, SetStateAction, useState, useEffect, useRef} from 'react';
import {t} from "i18next";
import {Button, Form, Modal} from "react-bootstrap";
import {Trans} from "react-i18next";

interface FilterProps {
    filters: any,
    setFilters: Dispatch<SetStateAction<any>>,
    field: string,
    fieldLabel?: string
}

const FilterText = ({filters, setFilters, field, fieldLabel}: FilterProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement | null>(null); // Ref létrehozása az input mezőhöz

    // Az input mező fókuszálása, amikor a modal megjelenik
    useEffect(() => {
        if (showModal && inputRef.current) {
            inputRef.current.focus();
        }
    }, [showModal]);

    // Helper function to handle text input changes
    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilters((prevState: any) => ({
            ...prevState,
            [field]: newValue
        }));
    };

    // Input fókuszvesztésének figyelése a modal bezárásához
    const handleBlur = () => {
        setShowModal(false);
    };

    // Enter gomb lenyomására a modal bezárása
    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();  // Megakadályozzuk az alapértelmezett form elküldési viselkedést
        setShowModal(false); // Bezárjuk a modalt
    };

    return (
        <div>
            {(!filters[field] || filters[field].length === 0) && (
                <button
                    className="text-muted cursor-pointer text-nowrap btn btn-link p-0"
                    onClick={() => setShowModal(true)}
                >
                    <small>{t('filter.empty')}</small>
                </button>
            )}

            {filters[field] && filters[field].length > 0 && (
                <div>
                    <button
                        className="text-start btn btn-link p-0"
                        onClick={() => setShowModal(true)}
                    >
                        <small className="text-muted text-nowrap ">{t('filter.filtered')}</small>
                        <div className="fw-bold">
                            {filters[field]}
                        </div>
                    </button>
                </div>
            )}

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t('filter.modalTitle')}: {t(fieldLabel ?? '')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="d-flex justify-content-between mb-4">
                        <Button variant="secondary" size="sm" className="ms-2" onClick={() => setFilters((prevState: any) => ({
                            ...prevState,
                            [field]: ''
                        }))}>
                            {t('filter.clear')}
                        </Button>
                        <Button variant="secondary" size="sm" className="ms-2" onClick={() => setFilters([])}>
                            {t('filter.clearAll')}
                        </Button>
                    </div>
                    <form onSubmit={handleSubmit}>
                        <Form.Group className="mb-3" controlId="filter-text-value">
                            <Form.Label>{t('filter.searchText')}</Form.Label>
                            <Form.Control
                                type="text"
                                ref={inputRef}  // Ref hozzárendelése az input mezőhöz
                                value={filters[field] || ''}  // If undefined, default to empty string
                                onChange={handleInputChange}
                            />
                        </Form.Group>
                    </form>
                    {/*<div className="text-center small text-muted" dangerouslySetInnerHTML={{ __html: t('filter.textDescription') }} />*/}
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default FilterText;
