import React, {Dispatch, SetStateAction, useState, useEffect} from 'react';
import {DateRange, Range, RangeKeyDict} from 'react-date-range';
import {addDays} from 'date-fns';
import 'react-date-range/dist/styles.css'; // fő CSS fájl
import 'react-date-range/dist/theme/default.css'; // alapértelmezett téma CSS
import {t} from "i18next";
import {Modal, Button} from "react-bootstrap";

interface FilterProps {
    filters: any,
    setFilters: Dispatch<SetStateAction<any>>,
    field: string,
    fieldLabel?: string
}

const FilterDate = ({filters, setFilters, field, fieldLabel}: FilterProps) => {
    const [showModal, setShowModal] = useState<boolean>(false);
    const [range, setRange] = useState<Range[]>([
        {
            startDate: filters[field]?.[0] ? new Date(filters[field][0]) : undefined,
            endDate: filters[field]?.[1] ? new Date(filters[field][1]) : undefined,
            key: 'selection'
        }
    ]);

    // Az input mező fókuszálása, amikor a modal megjelenik
    useEffect(() => {
        if (showModal) {
            setRange([
                {
                    startDate: filters[field]?.[0] ? new Date(filters[field][0]) : new Date(),
                    endDate: filters[field]?.[1] ? new Date(filters[field][1]) : addDays(new Date(), 7),
                    key: 'selection'
                }
            ]);
        }
    }, [showModal, filters, field]);

    // Dátum intervallum beállítása a filters állapotban
    const handleDateChange = (rangesByKey: RangeKeyDict) => {
        const newRange = rangesByKey['selection'];  // A range kulcs segítségével elérjük a kijelölt tartományt
        setRange([newRange]);
        setFilters((prevState: any) => ({
            ...prevState,
            [field]: [
                newRange.startDate ? newRange.startDate.toISOString() : undefined,
                newRange.endDate ? newRange.endDate.toISOString() : undefined
            ]
        }));
    };

    // Dátum intervallum törlése
    const handleReset = () => {
        setRange([{
            startDate: undefined,
            endDate: undefined,
            key: 'selection'
        }]);
        setFilters((prevState: any) => ({
            ...prevState,
            [field]: [undefined, undefined]  // Dátumtartomány törlése
        }));
    };

    // Dátum intervallum törlése
    const handleResetAll = () => {
        setRange([{
            startDate: undefined,
            endDate: undefined,
            key: 'selection'
        }]);
        setFilters([]);
    };

    return (
        <div>
            {(!filters[field] || !filters[field][0] || !filters[field][1]) && (
                <button
                    className="text-muted cursor-pointer text-nowrap btn btn-link p-0"
                    onClick={() => setShowModal(true)}
                >
                    <small>{t('filter.empty')}</small>
                </button>
            )}

            {filters[field] && filters[field][0] && filters[field][1] && (
                <div>
                    <button
                        className="btn btn-link p-0"
                        onClick={() => setShowModal(true)}
                    >
                        <small className="text-muted text-nowrap">{t('filter.filtered')}</small>
                        <div className="fw-bold">
                            {`${new Date(filters[field][0]).toLocaleDateString()} - ${new Date(filters[field][1]).toLocaleDateString()}`}
                        </div>
                    </button>
                </div>
            )}

            <Modal show={showModal} onHide={() => setShowModal(false)}>
                <Modal.Header closeButton>
                    {/*TODO: a fieldlabel mindenhol kapja meg a t() függvényt*/}
                    <Modal.Title>{t('filter.modalTitle')}: {fieldLabel}</Modal.Title>
                </Modal.Header>
                <Modal.Body className="d-flex flex-column gap-2">
                    <div className="d-flex justify-content-between">
                        <Button variant="secondary" size="sm" className="ms-2" onClick={handleReset}>
                            {t('filter.clear')}
                        </Button>
                        <Button variant="secondary" size="sm" className="ms-2" onClick={handleResetAll}>
                            {t('filter.clearAll')}
                        </Button>
                    </div>

                    <DateRange
                        editableDateInputs={true}
                        onChange={handleDateChange}
                        moveRangeOnFirstSelection={false}
                        ranges={range}
                        className="calendar-element"
                    />
                </Modal.Body>
            </Modal>
        </div>
    );
};

export default FilterDate;
