import { Table, Stack } from "react-bootstrap";
import { t, use } from "i18next";
import { Api } from "services/api";
import { useState, useEffect, useContext, useCallback } from "react";
import { ITrainingMessage } from "interfaces/ITrainingMessage";
import { usePermissions } from "hooks/usePermissions";
import PermissionCheck from "components/permissioncheck/permissioncheck";
import FilterDate from "components/filters/FilterDate";
import FilterSelect from "components/filters/FilterSelect";
import { ITrainingMessageStatus } from "interfaces/ITrainingMessageStatus";
import { IFilterChoicesList } from "interfaces/IFilterChoicesList";
import FilterText from "components/filters/FilterText";
import DataContext from "context/DataContext";


const TrainingMessagesPage = () => {

    //const { checkPermissions } = usePermissions();
    //const { profile } = useContext(DataContext);
    const [trainingMessages, setTrainingMessages] = useState<ITrainingMessage[]>([]);
    const [filteredTrainingMessages, setFilteredTrainingMessages] = useState<ITrainingMessage[]>([]);
    const [filters, setFilters] = useState<any>({});
    const [statuses, setStatuses] = useState<IFilterChoicesList>({} as IFilterChoicesList);

    const getTrainingMessages = useCallback(async () => {
        try {
            const response = await Api.get<ITrainingMessage[]>('training-message/list-messages')
            setTrainingMessages(response);
        } catch (error) {
            console.error(error);
        }
        //console.log('getTrainingMessages');
    }, []);

    useEffect(() => {
        Api.get<ITrainingMessageStatus[]>('training-message/list-statuses').then(response => {
            const statusesData: IFilterChoicesList = {}
            response.forEach((status) => {
                statusesData[status.title] = <><div className="px-1">{status.title}</div></>
            });
            setStatuses(statusesData);
        });
        //console.log('get statuses');

        void getTrainingMessages();
    }, [getTrainingMessages]);

    useEffect(() => {
        const filtered = trainingMessages.filter((trainingMessage) => {
            let result = true;

            // Típus szerinti szűrés
            if (typeof filters.status !== 'undefined' && filters.status.length && !filters.status.includes(trainingMessage.status)) {
                result = false;
            }

            // Cím szerinti szűrés
            if (typeof filters.assistant_name !== 'undefined' && filters.assistant_name.length && trainingMessage.assistant_name.toLowerCase().indexOf(filters.assistant_name.toLowerCase()) === -1) {
                result = false;
            }

            // User szerinti szűrés
            if (typeof filters.username !== 'undefined' && filters.username.length && trainingMessage.user.username.toLowerCase().indexOf(filters.username.toLowerCase()) === -1) {
                result = false;
            }

            // Dátum szerinti szűrés
            if (typeof filters.created_at !== 'undefined' && filters.created_at.length === 2) {
                const fileDate = new Date(trainingMessage.created_at); // A file.date string konvertálása Date objektummá
                const startDate = filters.created_at[0] ? new Date(filters.created_at[0]) : null; // Kezdő dátum konvertálása Date objektummá
                let endDate = filters.created_at[1] ? new Date(filters.created_at[1]) : null;   // Vég dátum konvertálása Date objektummá

                // Az endDate időpontját a nap végére állítjuk (23:59:59)
                if (endDate) {
                    endDate.setHours(23, 59, 59, 999);
                }

                // Csak akkor szűrjük meg, ha mind a kezdő, mind a vég dátum meg van adva
                if (startDate && endDate) {
                    if (fileDate < startDate || fileDate > endDate) {
                        result = false;  // Ha a fileDate kívül esik az intervallumon, akkor false
                    }
                }
            }

            return result;
        });

        setFilteredTrainingMessages(filtered);
    }, [filters, trainingMessages]);

    return (
        <PermissionCheck permission={['account.company_admin']}>
            <div className="card shadow-sm mt-2 mb-3 px-3 py-2">
                <Table responsive="sm" hover className="align-middle">
                    <thead>
                        <tr>
                            <th>
                                {t("training.submitted_by")}
                                <FilterText filters={filters} setFilters={setFilters} field="username"
                                    fieldLabel={t("training.submitted_by")}></FilterText>
                            </th>
                            <th>
                                {t("training.submission_time")}
                                <FilterDate filters={filters} setFilters={setFilters} field="created_at"
                                    fieldLabel={t("training.submission_time")}></FilterDate>
                            </th>
                            <th>
                                {t("training.status")}
                                <FilterSelect field="status" fieldLabel={t("training.status")} choices={statuses}
                                    filters={filters} setFilters={setFilters} />
                            </th>
                            <th>
                                {t("assistants.assistant")}
                                <FilterText filters={filters} setFilters={setFilters} field="assistant_name"
                                    fieldLabel={t("assistants.assistant")}></FilterText>
                            </th>
                            <th className="text-end">
                                {t("assistants.table.operations")}
                            </th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredTrainingMessages && filteredTrainingMessages.length > 0 && filteredTrainingMessages.map((message: ITrainingMessage) => (
                            <tr key={message.id}>
                                <td className={message.read_it ? 'fw-normal' : 'fw-bold'}>{message.user.username}</td>
                                <td className={message.read_it ? 'fw-normal' : 'fw-bold'}>{message.created_at}</td>
                                <td className={message.read_it ? 'fw-normal' : 'fw-bold'}>{message.status}</td>
                                <td className={message.read_it ? 'fw-normal' : 'fw-bold'}>{message.assistant_name}</td>
                                <td className="text-end">
                                    <Stack direction="horizontal" gap={2} className="justify-content-end">
                                        <a href={`/training-message-details/${message.id}`} className="btn btn-sm btn-primary">{t("training.view")}</a>
                                    </Stack>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </Table>
            </div>
        </PermissionCheck>
    );
}

export default TrainingMessagesPage;