import React, {Dispatch, SetStateAction, useEffect, useState} from 'react'
import {Button, Modal, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import {t} from "i18next";
import {IGroup} from "interfaces/IGroup";
import {
    BsCameraVideoFill,
    BsFiletypeDoc,
    BsFillKeyFill,
    BsFillVolumeUpFill,
    BsTrashFill,
    BsYoutube
} from "react-icons/bs";
import {IoMdAdd} from "react-icons/io";
import {Api} from "services/api";
import PermissionCheck from 'components/permissioncheck/permissioncheck';
import FilterText from "components/filters/FilterText";
import FilterSelect from "../../../components/filters/FilterSelect";

interface IPermission {
    id: string;
    name: string;
    code_name: string;
}

const StateChoices = {
    'true': <BsTrashFill size={24}/>,
    'false': <IoMdAdd size={24}/>,
}

const hasPermission = (Permission: IPermission, groupPermissions: IPermission[]) => {
    const filtered = groupPermissions.filter((groupPermission: IPermission) => {
        return groupPermission.id == Permission.id
    });
    return filtered.length > 0;
}

export default function Permissions({group}: {
    group: IGroup
}) {
    const [showModal, setShowModal] = useState(false);
    const [groupPermissions, setGroupPermissions] = useState<IPermission[]>([]);
    const [allPermissions, setAllPermissions] = useState<IPermission[]>([]);
    const [filteredPermissions, setFilteredPermissions] = useState<IPermission[]>([]);
    const [filters, setFilters] = useState<any>({});


    const togglePermission = async (Permission: IPermission) => {
        let url;
        let needConfirm = false;
        if (hasPermission(Permission, groupPermissions)) {
            url = 'groups/' + group!.id + '/removepermission/' + Permission.id;
            needConfirm = true;
        } else {
            url = 'groups/' + group!.id + '/addpermission/' + Permission.id;
        }
        if (!needConfirm || window.confirm(t('group.permissions.confirmRemove'))) {
            const response = await Api.get<IPermission[]>(url)
            setGroupPermissions(response);
        }
    }

    const loadPermissions = async () => {
        let response = await Api.get<IPermission[]>('groups/permissions')
        setAllPermissions(response);
        response = await Api.get<IPermission[]>('groups/' + group.id + '/permissions')
        setGroupPermissions(response);
    };

    useEffect(() => {
        if (showModal) {
            void loadPermissions();
        }
    }, [showModal]);

    useEffect(() => {
        const filtered = allPermissions.filter((permission) => {
            let result = true;

            if (typeof filters.name !== 'undefined' && filters.name.length && permission.name.toLowerCase().indexOf(filters.name.toLowerCase()) === -1) {
                result = false;
            }

            if (typeof filters.operations !== 'undefined' && filters.operations.length && filters.operations.length > 0) {
                const hasPerm = hasPermission(permission, groupPermissions);
                if (
                    (hasPerm && !filters.operations.includes('true')) ||
                    (!hasPerm && !filters.operations.includes('false'))
                ) {
                    result = false;
                }
            }

            return result;
        });

        setFilteredPermissions(filtered);
    }, [filters, allPermissions]);

    return (
        <PermissionCheck permission={['auth.view_permission']}>
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip-2">
                    <div>{t('group.permissions.iconTooltip')}</div>
                </Tooltip>}
            >
            <Button
                variant="outline-secondary"
                size="sm"
                onClick={e => {
                    setShowModal(true)
                }}
            >
                <BsFillKeyFill/>
            </Button>
            </OverlayTrigger>
            <Modal show={showModal} onHide={() => setShowModal(false)} centered size="lg" className="full-height-modal">
                <Modal.Header closeButton>
                    <Modal.Title>{t("group.permissions.modalTitle")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="scroll-container">
                        <div className="scroll-inner">
                            <Table responsive="sm" hover className="align-middle">
                                <thead>
                                <tr>
                                    <th>
                                        {t("group.permissions.table.name")}
                                        <FilterText filters={filters} setFilters={setFilters} field="name"
                                                    fieldLabel="group.permissions.table.name"></FilterText>
                                    </th>
                                    <th className="text-end">
                                        {t("group.operations")}
                                        <FilterSelect field="operations" fieldLabel={t("group.operations")}
                                                      choices={StateChoices} filters={filters} setFilters={setFilters}/>
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                {filteredPermissions.length === 0 && <tr>
                                    <td colSpan={2} className="text-center">{t('group.permissions.noPermission')}</td>
                                </tr>}
                                {filteredPermissions.map(permission => <tr key={permission.id}>
                                    <td>
                                        {permission.name}
                                    </td>
                                    <td className="text-end">
                                        {hasPermission(permission, groupPermissions) ?
                                            <Button onClick={() => togglePermission(permission)}
                                                    variant="outline-danger">
                                                <BsTrashFill/>
                                            </Button> :
                                            <Button onClick={() => togglePermission(permission)}
                                                    variant="outline-secondary">
                                                <IoMdAdd/>
                                            </Button>
                                        }
                                    </td>
                                </tr>)}
                                </tbody>
                            </Table>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </PermissionCheck>
    )
}
