import React, {FormEvent, useState} from 'react';
import PermissionCheck from "components/permissioncheck/permissioncheck";
import {BsFillInfoCircleFill, BsFillVolumeUpFill} from "react-icons/bs";
import {t} from "i18next";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import toast from "react-hot-toast";
import {Api} from "../../../services/api";

const AddAudio = ({getList}: {getList: () => void}) => {

    const [showModal, setShowModal] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [fileName, setFileName] = useState<File>();

    const formSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (fileName === undefined) {
            toast.error(t("file.validation.audioFormFieldsEmpty"));
            return false;
        }

        const allowedExtensions = /\.(mp3|m4a)$/i;
        if (!allowedExtensions.test(fileName.name)) {
            toast.error(t("file.validation.audioTypeNotValid"));
            return false;
        }

        const formData = new FormData();
        formData.append('type', 'audio');
        formData.append('platform', 'web');
        formData.append('file_name', fileName!);

        try {
            setIsUploading(true)
            Api.raw('file/upload', {
                method: 'POST',
                body: formData,
                headers: {},
            }).then((response: any) => {
                getList();
                setShowModal(false);
                toast.success(t("file.audio_upload_success"));
            }).catch((e) => {
                toast.error(t("file.audio_upload_danger"));
            }).finally(() => {
                setIsUploading(false)
            });
        } catch (e) {
            console.error(e)
            setIsUploading(false)
        }

        return false;
    }

    return (
        <PermissionCheck permission={['file.add_file', 'file.add_audio']}>
            <Button onClick={e => setShowModal(true)}
                    className="mb-3 d-inline-flex align-items-center justify-content-center">
                <BsFillVolumeUpFill size={24}/> <span
                className="ms-2">{t('file.addAudio')}</span>
            </Button>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{t("audio.localAudio")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formSubmit}>
                        <Form.Group
                            className="my-3"
                            controlId="file_name"
                            onChange={e => setFileName((e.target as HTMLInputElement).files![0])}>
                            <Form.Label>{t('audio.audioFileName')}</Form.Label>
                            <Form.Control type="file" placeholder={t('audio.audioFileNamePlaceholder')}
                                          required={true}/>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            {isUploading && <Spinner animation="border" variant="light" style={{
                                "width": "15px",
                                "height": "15px"
                            }}/>} {t('audio.fileSubmit')}
                        </Button>
                    </Form>
                    {isUploading &&
                        <div className="alert alert-primary mt-2 d-flex align-items-center" role="alert">
                            <div className="me-1">
                                <BsFillInfoCircleFill/>
                            </div>
                            <span>{t('audio.audio_upload_alert')}</span>
                        </div>}
                </Modal.Body>
            </Modal>
        </PermissionCheck>
    );
};

export default AddAudio;