import { useContext, useState } from 'react';
import { Button } from "react-bootstrap";
import { BsLayoutSidebar } from "react-icons/bs";
import SidePanel from "./SidePanel";
import MessageList from "./MessageList";
import Form from "./Form";
import { Toaster } from "react-hot-toast";
import ChatContext from "../context/ChatContext";
import PermissionCheck from 'components/permissioncheck/permissioncheck';

const user_id = parseInt(localStorage.getItem('user_id') ?? '0');

const Main = () => {

  const { assistant } = useContext(ChatContext);

  const [show, setShow] = useState(false);

  const handleClick = () => setShow(prevState => !prevState);

  return (
    <>
      <PermissionCheck permission={'chat.view_chat'} force={!assistant.id || assistant.owner_id === user_id}>
        <div id="cc-side-panel-button-container">
          <Button onClick={handleClick}
            className="position-absolute top-0 left-0 m-3 z-3">
            <BsLayoutSidebar />
          </Button>
        </div>

        <div
          id="cc-chat-grid"
          className={(show ? ' opened' : '')}>
          <SidePanel />
          <MessageList showAssistantDetails={true}
            showBackButton={true}
            showSourcesButton={true}
          />
          <Form showBotsBtn={true} />
        </div>
        <Toaster />
      </PermissionCheck>
    </>
  )
};

export default Main;
