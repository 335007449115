import React, { useEffect } from "react";

import { usePermissions } from "hooks/usePermissions";

import { ChatDataProvider } from "./context/ChatContext";
import { useInitialData } from "./hooks/useInitialData";

import './ChatPage.css';
import Main from "./components/Main";


const ChatPage = () => {
  const { checkPermissions } = usePermissions();

  useEffect(() => {
    checkPermissions(['chat.view_chat', 'assistant.view_assistantfiles', 'assistant.delete_assistantfiles']);
  }, []);

  return (
    <>
      <ChatDataProvider value={useInitialData()}>
        <Main />
      </ChatDataProvider>
    </>
  );
}

export default ChatPage;
