import { IMessageHistory } from 'interfaces/IMessageHistory';
import { useEffect, useState, createContext, useContext } from 'react';
import { Container } from 'react-bootstrap';
import { Link, useParams } from 'react-router-dom';
import { Api } from 'services/api';
import MessageDataCard from './components/MessageDataCard';
import AssistantDataCard from './components/AssistantDataCard';
import { ITrainingMessageDetails } from 'interfaces/ITrainingMessageDetails';
import { IAssistantFile } from 'interfaces/IAssistantFile';
import { Toaster } from 'react-hot-toast';
import { t } from "i18next";
import PermissionCheck from 'components/permissioncheck/permissioncheck';
import { usePermissions } from 'hooks/usePermissions';

const TrainingMessageContext = createContext<ITrainingMessageDetails | null>(null);

const TrainingMessageDetailsPage = () => {
    const { checkPermissions } = usePermissions();

    const { id } = useParams<{ id: string }>();

    const [trainingMessage, setTrainingMessage] = useState<ITrainingMessageDetails | null>(null);
    const [assistant, setAssistant] = useState<any | null>(null);
    const [messageHistory, setMessageHistory] = useState<IMessageHistory | null>(null);
    const [files, setFiles] = useState<IAssistantFile[]>([]);

    const getTrainingMessage = async (training_message_id: number) => {
        return await Api.get<ITrainingMessageDetails>('training-message/details/' + training_message_id)
    }

    const getAssistant = async (assistant_id: number) => {
        return await Api.get<any>('assistant/assistant/' + assistant_id);
    }

    const getMessageHistory = async (thread_id: string, assistant_id: string) => {
        return await Api.post<IMessageHistory>(`chat/get_thread_history/`, {
            thread_id: thread_id,
            assistant_id: assistant_id
        })
    }

    const getAssistantFiles = async (assistant_id: string) => {
        return Api.get<IAssistantFile[]>(`/assistant/${assistant_id}/files`)
    };

    useEffect(() => {
        checkPermissions([
            'account.company_admin',
        ]);

        const fetchData = async () => {
            try {
                const response = await getTrainingMessage(Number(id));
                if (response.assistant_id) {
                    setTrainingMessage(response);

                    // További adatok lekérése
                    const [assistantData, assistantFilesData] = await Promise.all([
                        getAssistant(response.assistant_id),
                        getAssistantFiles(response.openai_assistant_id)
                    ])

                    const messagesData = await getMessageHistory(response.thread_id, assistantData.assistant_id)

                    setAssistant(assistantData);
                    setMessageHistory(messagesData);
                    setFiles(assistantFilesData);
                }
            } catch (error) {
                console.error(error);
            }
        };

        fetchData();
    }, [id]);

    return (
        <PermissionCheck permission={['account.company_admin']}>
            <TrainingMessageContext.Provider value={trainingMessage}>
                <Container className="position-relative pb-5 chat-container mt-4">
                    <Link to='/'>
                        <button className="btn btn-primary mb-3">{t("training.back")}</button>
                    </Link>
                    <div className="card shadow-sm mb-3">
                        <div className="card-header">
                            <h5 className="my-2">{t("training.content_correction_request_details")}</h5>
                        </div>
                        <div className="card-body px-3 py-2">
                            <div className="row">
                                <div className='col-6'>
                                    <MessageDataCard messageHistory={messageHistory} assistant={assistant} />
                                </div>
                                <div className='col-6'>
                                    <AssistantDataCard assistant={assistant} files={files} />
                                </div>
                            </div>
                        </div>
                    </div>
                </Container>
                <Toaster position="top-right" />
            </TrainingMessageContext.Provider>
        </PermissionCheck>
    );
}

const useTrainingMessage = () => {
    const context = useContext(TrainingMessageContext);
    if (context === undefined) {
        throw new Error('useTrainingMessage must be used within a TrainingMessageProvider');
    }
    return context;
};

export { TrainingMessageDetailsPage, useTrainingMessage };