import { useContext, useEffect, useState } from "react";
import ChatContext from "../context/ChatContext";
import { Api } from "../../../services/api";

interface Thread {
    thread_id: string;
    title?: string;
    created_at_category: string;
}

const Threads = () => {
    const { assistantId, setThreadId } = useContext(ChatContext);
    const [threads, setThreads] = useState<Thread[]>([]);
    const [threadCategories, setThreadCategories] = useState<any>([]);

    useEffect(() => {
        Api.get<Thread[]>(`/chat/${assistantId}/threads`).then(response => {
            setThreads(response);
            const checkNames = async (response: Thread[]) => {
                const threadsWithoutTitle = response.filter(thread => !thread.title);
                if (threadsWithoutTitle.length > 0) {
                    const responses = await Promise.all(
                        threadsWithoutTitle.map(thread =>
                            Api.get<Thread>(`chat/thread/${thread.thread_id}/get-name`)
                        )
                    );
                    const updatedThreads = response.map(thread => {
                        const responseThread = responses.find(resp => resp.thread_id === thread.thread_id);
                        if (responseThread) {
                            return { ...thread, title: responseThread.title };
                        }
                        return thread;
                    });
                    setThreads(updatedThreads);
                }
            }
            checkNames(response);
        })
    }, []);

    useEffect(() => {
        const fullCategories = threads.flatMap(item => item.created_at_category);
        // @ts-ignore
        const categories = [...new Set(fullCategories)];
        setThreadCategories(categories);
    }, [threads])

    const pickThread = (thread_id: string) => {
        setThreadId(thread_id);
        localStorage.setItem('thread_id', thread_id);
    }

    return (
        <>
            {threadCategories.length > 0 && <>
                {threadCategories.map((category: string) => <div key={category}>
                    <h3>{category}</h3>
                    <ul>
                        {threads.map((thread) => (
                            thread.created_at_category === category &&
                            <li key={thread.thread_id} onClick={e => pickThread(thread.thread_id)} title={thread.title ? thread.title : thread.thread_id}>
                                {thread.title ? thread.title : thread.thread_id}
                            </li>
                        ))}
                    </ul>
                </div>
                )}
            </>}
        </>
    );
}
export default Threads;