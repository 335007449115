import { IAssistantFile } from "../../../interfaces/IAssistantFile";
import IAssistant from "../../../interfaces/IAssistant";
import FileType from "../../../components/FileType";
import OverlayTrigger from 'react-bootstrap/esm/OverlayTrigger';
import Tooltip from 'react-bootstrap/esm/Tooltip';
import { Link } from 'react-router-dom';
import Button from 'react-bootstrap/esm/Button';
import { FaFileCode, FaFilePdf } from 'react-icons/fa';
import { IoMdDownload } from 'react-icons/io';
import { t } from 'i18next';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { useTrainingMessage } from "../TrainingMessageDetailsPage";
import { useEffect, useState } from "react";

const AssistantDataCard = ({ assistant, files }: { assistant: IAssistant | null, files: IAssistantFile[] }) => {
    const trainingMessage = useTrainingMessage();

    const [messageSources, setMessageSources] = useState<any[]>([]);

    useEffect(() => {
        if (trainingMessage) {
            setMessageSources(Object.values(trainingMessage?.chat_message_sources));
        }
    }, [trainingMessage]);

    return (
        <div className="card shadow-sm">
            <div className="card-header">{t("training.assistant_data")}</div>
            <div className="card-body">
                <div className="card mb-3 shadow-sm">
                    {<div className="card-body">
                        {assistant && <div className="row">
                            <div id="assistant-details" className="col-3">
                                <div className="assistant-profile-img">
                                    <img src={assistant.profile_image} alt={assistant.name} />
                                </div>
                            </div>
                            <div className="col-9">
                                <h5>{assistant.name}</h5>
                                <div dangerouslySetInnerHTML={{ __html: assistant.short_description }} />
                                <div dangerouslySetInnerHTML={{ __html: assistant.description }} />
                            </div>
                        </div>}
                    </div>}
                </div>
                <div id="assistant-file-tabs">
                    <Tabs defaultActiveKey="tab01" className="mb-3">
                        <Tab eventKey="tab01" title={t("training.assistant_files")}>
                            <div className="card">
                                <div className="card-header">{t("training.assistant_files")}</div>
                                <div className="card-body">
                                    {files.length > 0 ? (
                                        <ul className='list-group'>
                                            {files.map((file) => (
                                                <li key={file.file_id} className='list-group-item'>
                                                    <div className="file-details">
                                                        <div className="file-title d-flex">
                                                            <span className="me-2">
                                                                <FileType file={file} />
                                                            </span>
                                                            <span className="file-name">{file.title}</span>
                                                            <div className="ms-auto">
                                                                {file.type !== 'document' &&
                                                                    <OverlayTrigger
                                                                        placement="bottom"
                                                                        overlay={<Tooltip id="button-tooltip-2">
                                                                            <div>{t('file.pdf_export')}</div>
                                                                        </Tooltip>}
                                                                    >
                                                                        <Link to={file.pdfUrl!} target="_blank">
                                                                            <Button variant="outline-secondary" size="sm">
                                                                                <FaFilePdf size={18} />
                                                                            </Button>
                                                                        </Link>
                                                                    </OverlayTrigger>
                                                                }
                                                                {file.type === 'document' &&
                                                                    <OverlayTrigger
                                                                        placement="bottom"
                                                                        overlay={<Tooltip id="button-tooltip-2">
                                                                            <div>{t('file.documentUrl')}</div>
                                                                        </Tooltip>}
                                                                    >
                                                                        <Link to={file.documentUrl!} target="_blank">
                                                                            <Button variant="outline-secondary" size="sm">
                                                                                <IoMdDownload size={18} />
                                                                            </Button>
                                                                        </Link>
                                                                    </OverlayTrigger>
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </li>
                                            ))}
                                        </ul>
                                    ) : (
                                        <div className="alert alert-warning" role="alert">
                                            {t("training.no_files_available")}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Tab>
                        <Tab eventKey="tab02" title={t("training.affected_files")}>
                            <div className="card">
                                <div className="card-header">{t("training.affected_files")}</div>
                                <div className="card-body">
                                    {messageSources.length > 0 ? (
                                        messageSources.map((item: any, index: number) => (
                                            <div key={index} className='py-2 px-3 rounded border border-1 mb-2'>
                                                <div>{t("message_buttons.type")}: {item.doc_type === 'document' ? t("message_buttons.doc_type_document") : t("message_buttons.doc_type_audio")}</div>
                                                <div>
                                                    {t("message_buttons.title")}: <a target='_blank' className='downloadUrl file-name' href={item.download_url} download rel="noreferrer">{item.title}</a>
                                                </div>
                                            </div>
                                        ))
                                    ) : (
                                        <div className="alert alert-warning" role="alert">
                                            {t("training.no_files_for_problematic_message")}
                                        </div>
                                    )}
                                </div>
                            </div>
                        </Tab>
                    </Tabs>
                </div>
            </div>
        </div>
    );
}

export default AssistantDataCard;