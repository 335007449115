import React, {useEffect, useState} from 'react';
import {t} from "i18next";
import FilterText from "components/filters/FilterText";
import FilterSelect from "components/filters/FilterSelect";
import {Button, Table} from "react-bootstrap";
import {BsTrashFill} from "react-icons/bs";
import {IoMdAdd} from "react-icons/io";
import {IGroup} from "interfaces/IGroup";
import IFile from "interfaces/IFile";
import {Api} from "services/api";

const hasGroup = (group: IGroup, fileGroups: IGroup[]) => {
    const filtered = fileGroups.filter((fileGroup: IGroup) => {
        return fileGroup.id == group.id
    });
    return filtered.length > 0;
}

const StateChoices = {
    'true': <BsTrashFill size={24}/>,
    'false': <IoMdAdd size={24}/>,
}

interface GroupsProps {
    file: IFile,
    showModal?: boolean
}

const Groups = ({file, showModal}: GroupsProps) => {
    const [allGroups, setAllGroups] = useState<IGroup[]>([]);
    const [fileGroups, setFileGroups] = useState<IGroup[]>([]);
    const [filteredGroups, setFilteredGroups] = useState<IGroup[]>([]);
    const [filters, setFilters] = useState<any>({});

    const loadGroups = async (file: IFile) => {
        let response = await Api.get<IGroup[]>('file/groups')
        setAllGroups(response);
        response = await Api.get<IGroup[]>('file/' + file.id + '/groups')
        setFileGroups(response);
    };

    const toggleGroup = async (group: IGroup) => {
        let url;
        let needConfirm = false;
        if (hasGroup(group, fileGroups)) {
            url = 'file/' + file!.id + '/removegroup/' + group.id;
            needConfirm = true;
        } else {
            url = 'file/' + file!.id + '/addgroup/' + group.id;
        }
        if (!needConfirm || window.confirm(t('file.groups.confirmRemove'))) {
            const response = await Api.get<IGroup[]>(url)
            setFileGroups(response);
        }
    }

    useEffect(() => {
        if (file && showModal) {
            void loadGroups(file);
        }
    }, [showModal, file]);
    useEffect(() => {
        const filtered = allGroups.filter((group) => {
            let result = true;

            if (typeof filters.groupname !== 'undefined' && filters.groupname.length && group.name.toLowerCase().indexOf(filters.groupname.toLowerCase()) === -1) {
                result = false;
            }

            if (typeof filters.operations !== 'undefined' && filters.operations.length && filters.operations.length > 0) {
                const hasUsr = hasGroup(group, fileGroups);
                if (
                    (hasUsr && !filters.operations.includes('true')) ||
                    (!hasUsr && !filters.operations.includes('false'))
                ) {
                    result = false;
                }
            }

            return result;
        });

        setFilteredGroups(filtered);
    }, [filters, allGroups]);

    return (
        <Table responsive="sm" hover className="align-middle">
            <thead>
            <tr>
                <th>
                    {t("file.rights.groups.table.name")}
                    <FilterText filters={filters} setFilters={setFilters} field="groupname"
                                fieldLabel="file.rights.groups.table.name"></FilterText>
                </th>
                <th className="text-end">
                    {t("file.operations")}
                    <FilterSelect field="operations" fieldLabel={t("file.operations")}
                                  choices={StateChoices} filters={filters} setFilters={setFilters}/>
                </th>
            </tr>
            </thead>
            <tbody>
            {filteredGroups.length === 0 && <tr>
                <td colSpan={2} className="text-center">{t('file.groups.noGroup')}</td>
            </tr>}
            {filteredGroups.map(group => <tr key={group.id}>
                <td>
                    {group.name}
                </td>
                <td className="text-end">
                    {hasGroup(group, fileGroups) ?
                        <Button onClick={() => toggleGroup(group)} variant="outline-danger">
                            <BsTrashFill/>
                        </Button> :
                        <Button onClick={() => toggleGroup(group)} variant="outline-secondary">
                            <IoMdAdd/>
                        </Button>
                    }
                </td>
            </tr>)}
            </tbody>
        </Table>
    );
};

export default Groups;