import { BiDislike } from "react-icons/bi";
import React, { useContext, useEffect, useState } from "react";
import { Modal, Button } from "react-bootstrap";
import TooltipLayer from "../../../../components/tooltip/tooltip";
import { t, use } from "i18next";
import { Api } from "../../../../services/api";
import toast, { Toaster } from "react-hot-toast";
import DataContext from "../../../../context/DataContext";
import { IMessage } from "../../../../interfaces/IMessage";

const MessageDislikeBtn = ({ message }: { message: IMessage }) => {
    const { profile } = useContext(DataContext);
    const [showModal, setShowModal] = useState<boolean>(false);
    const [suggestions, setSuggestions] = useState<string | null>(null);
    const [textArea01, setTextArea01] = useState('');
    const [textArea02, setTextArea02] = useState('');

    useEffect(() => {
        if (suggestions === 'option2') {
            document.getElementById('correction-suggestion')?.classList.remove('d-none');
        }
        if (suggestions === 'option1') {
            document.getElementById('correction-suggestion')?.classList.add('d-none');
        }
    }, [suggestions]);

    const handleSubmit = async () => {
        if (textArea01.trim().length === 0) {
            toast.error(t("training.dislike_form.whats_wrong_with_message_required"), {
                position: "top-center"
            });
            return;
        }

        if (suggestions === 'option2' && textArea02.trim().length === 0) {
            toast.error(t("training.dislike_form.correction_suggestion_field_is_required"), {
                position: "top-center"
            });
            return;
        }

        if (!suggestions) {
            toast.error(t("training.dislike_form.choose_suggestion_option"), {
                position: "top-center"
            });
            return;
        }

        let data = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                text1: textArea01,
                text2: textArea02,
                suggestions: (suggestions === 'option2' ? true : false),
                chat_message: message,
            })
        };

        await Api.raw<any>(`training-message/send-message`, data).then((response) => {
            console.log(response);
            if (response.status === 200) {
                toast.success(t("training.dislike_form.thank_you_feedback"), {
                    position: "top-center"
                });
                setShowModal(false);
            } else {
                toast.error(t("training.dislike_form.error_occurred_try_again"), {
                    position: "top-center"
                });
            }
        }).catch((error) => {
            console.error(error)
        });
    }

    const handleOptionChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setSuggestions(event.target.value);
    };

    return <>
        {message.message_id && <TooltipLayer title={t("message_buttons.dislike_modal_title")} id="tt-02">
            <button type="button"
                className="btn btn-link btn-sm d-flex justify-content-center align-items-center"
                onClick={e => setShowModal(true)}>
                <BiDislike size={18} />
            </button>
        </TooltipLayer>}

        <Modal show={showModal} onHide={() => setShowModal(false)} centered>
            <Modal.Header closeButton>
                <Modal.Title as='b'>{t("training.dislike_form.found_error_request_correction")}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div>
                    <div className="mb-2"><strong>{t("training.dislike_form.whats_wrong_with_message")}</strong></div>
                    <textarea className="form-control" rows={3}
                        placeholder={t("training.dislike_form.provide_guidance_to_admin")}
                        onChange={(e) => setTextArea01(e.target.value)}
                    />
                </div>
                <hr />
                <div className="mt-2">
                    <div className="mb-2"><strong>{t("training.dislike_form.what_do_you_suggest")}</strong></div>
                    <div className="mb-2">
                        <div className="form-check">
                            <input className="form-check-input" type="radio" id="formRadios1" name="suggestion" value="option1" onChange={handleOptionChange} />
                            <label className="form-check-label" htmlFor="formRadios1">
                                {t("training.delete")}
                            </label>
                        </div>
                        <div className="form-check">
                            <input className="form-check-input" type="radio" id="formRadios2" name="suggestion" value="option2" onChange={handleOptionChange} />
                            <label className="form-check-label" htmlFor="formRadios2">
                                {t("training.correct")}
                            </label>
                        </div>
                    </div>
                    <div id="correction-suggestion" className="d-none">
                        <div className="mb-2"><strong>{t("training.correction_suggestion")}</strong></div>
                        <textarea className="form-control" rows={3}
                            placeholder={t("training.dislike_form.help_admin_with_suggestion")}
                            onChange={(e) => setTextArea02(e.target.value)}
                        />
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="dark" className="mx-auto" onClick={handleSubmit}>{t("training.dislike_form.submit")}</Button>
            </Modal.Footer>
        </Modal>
        <Toaster position="top-right" />
    </>
}

export default MessageDislikeBtn;