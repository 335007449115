import { Tab, Tabs } from "react-bootstrap";
import { useContext, useState } from "react";
import { t } from "i18next";
import Threads from "./Threads";
import Files from "./Files";
import DataContext from "context/DataContext";

const SidePanel = () => {
  const [key, setKey] = useState<string>('threads');
  const { userPermissions } = useContext(DataContext);

  return (
    <>
      <div id="cc-side-panel">
        <div id="cc-side-panel-content">
          <Tabs
            activeKey={key}
            onSelect={(k) => setKey(k || '')}
          >
            <Tab eventKey="threads" title={t("chat.sidePanel.tab.threads")} className="threads">
              <Threads />
            </Tab>
            {userPermissions['assistant.view_assistantfiles'] &&
              <Tab eventKey="files" title={t("chat.sidePanel.tab.files")} className="files">
                <Files />
              </Tab>}
          </Tabs>
        </div>
      </div>
    </>
  );
}
export default SidePanel;
