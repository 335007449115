import IAssistant from "interfaces/IAssistant";
import React, {useContext, useEffect, useState} from "react";
import {Api} from "services/api";
import {FaCaretDown} from "react-icons/fa";
import ChatContext from "../context/ChatContext";

const AssistantDetails = () => {
    const [assistantId, setAssistantId] = useState<string>(() => {
        return localStorage.getItem('assistant_id') ?? '';
    });
    const [detailsOpen, setDetailsOpen] = useState<boolean>(false);

    const {assistant, setAssistant} = useContext(ChatContext);
    useEffect(() => {
        Api.get<IAssistant>(`assistant/${assistantId}`)
            .then(response => {
                setAssistant(response);
            })
    }, []);

    return (
        <div id="cc-assistant-details" className="pt-3">
            {assistant.profile_image &&
                <div className="assistant-profile-img">
                    <img src={assistant.profile_image} alt=""/>
                </div>}
            <div className="d-inline-flex justify-content-center align-items-center">
                <h1 style={{'cursor': assistant.description?.length > 0 ? 'pointer' : 'default'}}
                    onClick={() => setDetailsOpen(!detailsOpen)}>{assistant.name}</h1>
                {assistant.description?.length > 0 && <FaCaretDown className="ms-2"/>}
            </div>
            <div dangerouslySetInnerHTML={{__html: assistant.short_description}}/>
            <div style={{'display': detailsOpen ? 'block' : 'none'}}>
                <div dangerouslySetInnerHTML={{__html: assistant.description}}/>
            </div>
        </div>
    );
}
export default AssistantDetails;