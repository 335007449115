import React, {FormEvent, useState} from 'react';
import PermissionCheck from "components/permissioncheck/permissioncheck";
import {Button, Form, Modal, Spinner, Tab, Tabs} from "react-bootstrap";
import {BsCameraVideoFill, BsFillInfoCircleFill, BsYoutube} from "react-icons/bs";
import {t} from "i18next";
import toast from "react-hot-toast";
import {Api} from "../../../services/api";

enum VideoType {
    YouTube = 'youtube',
    Video = 'video'
}

const AddVideo = ({getList}: {getList: () => void}) => {
    const [showModal, setShowModal] = useState(false);
    const [videoType, setVideoType] = useState<VideoType>(VideoType.YouTube);
    const [url, setUrl] = useState<string | null>(null);
    const [isUploading, setIsUploading] = useState(false);
    const [fileName, setFileName] = useState<File>();

    const formSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (videoType === VideoType.Video && fileName === undefined) {
            toast.error(t("file.validation.videoFormFieldEmpty"));
            return false;
        }

        if (videoType === VideoType.YouTube && url === undefined) {
            toast.error(t("file.validation.audioFormFieldsEmpty"));
            return false;
        }

        const formData = new FormData();
        formData.append('type', videoType);
        formData.append('platform', 'web');
        if (videoType === VideoType.YouTube) {
            formData.append('url', url!);
        } else {
            formData.append('file_name', fileName!);
        }

        try {
            setIsUploading(true)
            Api.raw('file/upload', {
                method: 'POST',
                body: formData,
                headers: {},
            }).then((response: any) => {
                getList();
                setShowModal(false);
                toast.success(t("file.video_upload_success"));
            }).catch((e) => {
                toast.error(t("file.video_upload_danger"));
            }).finally(() => {
                setIsUploading(false)
            });
        } catch (e) {
            console.error(e)
            setIsUploading(false)
        }

        return false;
    }
    return (
        <PermissionCheck permission={['file.add_file', 'file.add_video']}>
            <Button onClick={e => setShowModal(true)}
                    className="mb-3 d-inline-flex align-items-center justify-content-center">
                <BsCameraVideoFill size={24}/> <span
                className="ms-2">{t('file.addVideo')}</span>
            </Button>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{videoType == VideoType.Video ? t("audio.localVideo") : t("audio.localYoutube")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs activeKey={videoType} onSelect={(k) => setVideoType(k as VideoType)}>
                        <Tab title={<><BsYoutube size={24}/> <span
                            className="ms-2">{t('audio.localYoutube')}</span></>} eventKey={VideoType.YouTube}>
                            <Form onSubmit={formSubmit}>
                                <Form.Group
                                    className="my-3"
                                    controlId="video_url"
                                >
                                    <Form.Label>{t('audio.videoUrl')}</Form.Label>
                                    <Form.Control
                                        type="text"
                                        placeholder={t('audio.youtubeUrlPlaceholder')}
                                        required={true}
                                        onChange={e => setUrl(e.target.value)}
                                    />
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    {isUploading && <Spinner animation="border" variant="light" style={{
                                        "width": "15px",
                                        "height": "15px"
                                    }}/>} {t('audio.processYoutube')}
                                </Button>
                            </Form>
                        </Tab>
                        <Tab title={<><BsCameraVideoFill size={24}/> <span
                            className="ms-2">{t('audio.localVideo')}</span></>} eventKey={VideoType.Video}>
                            <Form onSubmit={formSubmit}>
                                <Form.Group
                                    className="my-3"
                                    controlId="file_name"
                                    onChange={e => setFileName((e.target as HTMLInputElement).files![0])}>
                                    <Form.Label>{t('audio.videoFileName')}</Form.Label>
                                    <Form.Control type="file" placeholder={t('audio.videoFileNamePlaceholder')}
                                                  required={true}/>
                                </Form.Group>
                                <Button variant="primary" type="submit">
                                    {isUploading && <Spinner animation="border" variant="light" style={{
                                        "width": "15px",
                                        "height": "15px"
                                    }}/>} {t('audio.fileSubmit')}
                                </Button>
                            </Form>
                        </Tab>
                    </Tabs>

                    {isUploading &&
                        <div className="alert alert-primary mt-2 d-flex align-items-center" role="alert">
                            <div className="me-1">
                                <BsFillInfoCircleFill/>
                            </div>
                            <span>{t('audio.video_upload_alert')}</span>
                        </div>}
                </Modal.Body>
            </Modal>
        </PermissionCheck>
    );
};

export default AddVideo;