import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { IMessageHistory } from 'interfaces/IMessageHistory';
import Message from "pages/chat/components/Message";
import MessageClipboardBtn from "pages/chat/components/buttons/MessageClipboardBtn";
import MessageSourcesBtn from "pages/chat/components/buttons/MessageSourcesBtn";
import Notes from "./Notes";
import { useTrainingMessage } from "../TrainingMessageDetailsPage";
import { FaSave } from "react-icons/fa";
import { SetStateAction, useEffect, useState } from 'react';
import { Api } from 'services/api';
import { t } from "i18next";
import toast from "react-hot-toast";
import { ITrainingMessageStatus } from 'interfaces/ITrainingMessageStatus';
import IAssistant from 'interfaces/IAssistant';


const MessageDataCard = ({ messageHistory, assistant }: { messageHistory: IMessageHistory | null, assistant: IAssistant | null }) => {
    const trainingMessage = useTrainingMessage();

    const [statuses, setStatuses] = useState<ITrainingMessageStatus[]>([]);
    const [selectedStatus, setSelectedStatus] = useState(trainingMessage?.status_id || '');
    const [loading, setLoading] = useState<boolean>(false);
    const [assistantData, setAssistantData] = useState<IAssistant | null>(null);

    useEffect(() => {
        Api.get<ITrainingMessageStatus[]>('training-message/list-statuses').then(response => {
            setStatuses(response);
        });
    }, []);

    useEffect(() => {
        if (trainingMessage) {
            setSelectedStatus(trainingMessage.status_id);
        }
    }, [trainingMessage]);

    useEffect(() => {
        if (assistant) {
            setAssistantData(assistant);
        }
    }, [assistant]);

    const handleStatusChange = (e: any) => {
        setSelectedStatus(e.target.value);
    };

    const saveStatus = async () => {
        setLoading(true);
        try {
            let data = {
                method: 'POST',
                headers: {
                    "Content-Type": "application/json",
                    "Accept": "application/json",
                },
                body: JSON.stringify({
                    local_message_id: trainingMessage?.id,
                    status_id: selectedStatus,
                })
            };

            await Api.raw<any>(`training-message/update-status`, data).then((response) => {
                if (response.status === 200) {
                    toast.success(t("training.status_modified"), {
                        position: "top-center"
                    });
                }
            });
        } catch (error) {
            toast.error(t("training.status_save_error"), {
                position: "top-center"
            });
            console.error(error);
        } finally {
            setLoading(false);
        }
    }

    return <>
        <div className="card shadow-sm">
            <div className="card-header">
                {t("training.incoming_message_data")}
            </div>
            <div className="card-body">
                <Tabs defaultActiveKey="tab01" className="mb-3">
                    <Tab eventKey="tab01" title={t("training.data")}>
                        <div className="card mb-3">
                            <div className="card-body">
                                <h5 className="card-title">{t("training.user_data")}</h5>
                                <table className="table table-hover">
                                    <tbody>
                                        <tr>
                                            <td>{t("training.username")}:</td>
                                            <td>
                                                <div className="d-flex align-items-center">
                                                    {trainingMessage?.user.profile_image && <div className="profile-image">
                                                        <img src={trainingMessage?.user.profile_image} alt={trainingMessage?.user.username} />
                                                    </div>}
                                                    <div className="ms-2">{trainingMessage?.user.username}</div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t("training.name")}:</td>
                                            <td>
                                                {localStorage.getItem('lang') === 'en' ?
                                                    (trainingMessage?.user.first_name + ' ' + trainingMessage?.user.last_name) :
                                                    (trainingMessage?.user.last_name + ' ' + trainingMessage?.user.first_name)}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t("training.email")}:</td>
                                            <td>{trainingMessage?.user.email}</td>
                                        </tr>
                                        <tr>
                                            <td>{t("training.company")}:</td>
                                            <td>{trainingMessage?.user.company_name}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>

                        <div className="card">
                            <div className="card-body">
                                <h5 className="card-title">{t("training.message_data")}</h5>
                                <table className="table">
                                    <tbody>
                                        <tr>
                                            <td>{t("training.status")}:</td>
                                            <td>
                                                <div className='d-flex'>
                                                    <select className='form-select' value={selectedStatus} onChange={handleStatusChange}>
                                                        {statuses.map(status => (
                                                            <option key={status.id} value={status.id}>
                                                                {status.title}
                                                            </option>
                                                        ))}
                                                    </select>
                                                    <button type="button" className='btn btn-secondary ms-2' onClick={saveStatus}>
                                                        {loading ? <div className="spinner-border spinner-border-sm" role="status">
                                                            <span className="visually-hidden">Loading...</span>
                                                        </div> : <FaSave />}
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t("training.submission_time")}:</td>
                                            <td>{trainingMessage?.created_at}</td>
                                        </tr>
                                        <tr>
                                            <td colSpan={2}>
                                                <div id="trainingMessage" className="card">
                                                    <div className="card-header">
                                                        {t("training.issue_with_message")}
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="card message-block">
                                                            <p className="m-0 px-3 py-2">{trainingMessage?.message01 || '-'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>{t("training.delete_or_correct")}:</td>
                                            <td>{trainingMessage?.suggestions ? t("training.correct") : t("training.delete")}</td>
                                        </tr>
                                        {trainingMessage?.suggestions && <tr>
                                            <td colSpan={2}>
                                                <div id="trainingMessage" className="card">
                                                    <div className="card-header">
                                                        {t("training.correction_suggestion")}:
                                                    </div>
                                                    <div className="card-body">
                                                        <div className="card message-block">
                                                            <p className="m-0 px-3 py-2">{trainingMessage?.message02 || '-'}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </td>
                                        </tr>}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                        <div className="card mt-3">
                            <div className="card-header">
                                {t("training.problematic_message")}
                            </div>
                            <div className="card-body">
                                <div className="card">
                                    <p className="m-0 px-3 py-2">{trainingMessage?.chat_message}</p>
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="tab02" title={t("training.chat_history")}>
                        <div id="cc-message-list-container">
                            <div id="cc-message-list-content" className="px-3 position-relative">
                                <div id="cc-message-list">
                                    {assistantData?.id && messageHistory && messageHistory.messages.length > 0 && messageHistory.messages.map((message, index) => <div key={index}>
                                        <Message role={message.role} assistant_profile_image={assistantData.profile_image}>
                                            <div dangerouslySetInnerHTML={{
                                                __html: message.content
                                            }} />
                                            {message.role === 'assistant' && <div className='d-inline-flex justify-content-start align-items-center gap-2 mt-2 chat-message-icons'>
                                                <MessageClipboardBtn content={message.content}></MessageClipboardBtn>
                                                <MessageSourcesBtn message={message}></MessageSourcesBtn>
                                            </div>}
                                        </Message>
                                        <div className={`${message.role === 'assistant' ? 'text-end' : 'text-start'} px-2`} style={{
                                            fontSize: '14px'
                                        }}>{message.created_at}</div>
                                    </div>)}
                                </div>
                            </div>
                        </div>
                    </Tab>
                    <Tab eventKey="tab03" title={t("training.notes")}>
                        <Notes />
                    </Tab>
                </Tabs>
            </div>
        </div>
    </>;
};

export default MessageDataCard;