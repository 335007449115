import React, {FormEvent, useEffect, useState} from 'react';
import PermissionCheck from "components/permissioncheck/permissioncheck";
import {Button, Form, Modal, Spinner} from "react-bootstrap";
import {t} from "i18next";
import {FaFileUpload} from "react-icons/fa";
import {BsFillInfoCircleFill} from "react-icons/bs";
import toast from "react-hot-toast";
import {Api} from "services/api";

const AddDocument = ({getList}: {getList: () => void}) => {
    const [showModal, setShowModal] = useState(false);
    const [isUploading, setIsUploading] = useState(false);
    const [fileName, setFileName] = useState<File>();
    const [title, setTitle] = useState<string>('');

    const formSubmit = (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        if (fileName === undefined) {
            toast.error(t("file.validation.documentFormFieldsEmpty"));
            return false;
        }

        const allowedExtensions = /\.(docx|pdf|xlsx|html|md)$/i;
        if (!allowedExtensions.test(fileName.name)) {
            toast.error(t("file.validation.documentTypeNotValid"));
            return false;
        }

        const formData = new FormData();
        formData.append('title', title);
        formData.append('type', 'document');
        formData.append('platform', 'web');
        formData.append('file_name', fileName!);

        try {
            setIsUploading(true)
            Api.raw('file/upload', {
                method: 'POST',
                body: formData,
                headers: {},
            }).then((response: any) => {
                getList();
                setShowModal(false);
                toast.success(t("file.document_upload_success"));
            }).catch((e) => {
                toast.error(t("file.document_upload_danger"));
            }).finally(() => {
                setIsUploading(false)
            });
        } catch (e) {
            console.error(e)
            setIsUploading(false)
        }

        return false;
    }

    const handleFileChange = (event: FormEvent<HTMLFormElement>) => {
        const file = (event.target as HTMLInputElement).files![0]
        setFileName(file)
        if (!title) {
            setTitle(file.name)
        }
    };

    useEffect(() => {
        if (showModal) {
            setTitle('');
        }
    }, [showModal]);
    return (
        <PermissionCheck permission={['file.add_file', 'file.add_document']}>
            <Button onClick={e => setShowModal(true)}
                    className="mb-3 d-inline-flex align-items-center justify-content-center">
                <FaFileUpload size={24}/> <span
                className="ms-2">{t('file.addDocument')}</span>
            </Button>

            <Modal show={showModal} onHide={() => setShowModal(false)} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{t("documents.modalTitle")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form onSubmit={formSubmit}>
                        <div className="my-3">
                            <label htmlFor="document_title"
                                   className="form-label">{t('documents.documentTitle')}</label>
                            <Form.Control
                                type="text"
                                className="form-control"
                                id="document_title"
                                placeholder={t('documents.titlePlaceholder')} value={title}
                                onChange={e => setTitle((e.target as HTMLInputElement).value)} required={true}/>
                        </div>
                        <Form.Group
                            className="my-3"
                            controlId="file_name"
                            onChange={handleFileChange}>
                            <Form.Label>{t('documents.uploadFile')}</Form.Label>
                            <Form.Control type="file" placeholder={t('documents.uploadFile')}
                                          required={true}/>
                        </Form.Group>
                        <Button variant="primary" type="submit">
                            {isUploading && <Spinner animation="border" variant="light" style={{
                                "width": "15px",
                                "height": "15px"
                            }}/>} {t('documents.fileSubmit')}
                        </Button>
                    </Form>
                    {isUploading &&
                        <div className="alert alert-primary mt-2 d-flex align-items-center" role="alert">
                            <div className="me-1">
                                <BsFillInfoCircleFill/>
                            </div>
                            <span>{t('documents.document_upload_alert')}</span>
                        </div>}
                </Modal.Body>
            </Modal>
        </PermissionCheck>
    );
};

export default AddDocument;