import {Api} from "services/api";
import {IPermission} from "../interfaces/IPermission";
import {useContext} from "react";
import DataContext from "context/DataContext";

export const usePermissions = () => {
    const {userPermissions, setUserPermissions} = useContext(DataContext);

    const checkPermissions = (neededPermissions: string[]) => {

        const downloadablePermissions = neededPermissions.filter(permission => {
            return typeof userPermissions[permission] === "undefined";
        })
        Api.get<IPermission>("account/permission-check?" +
            downloadablePermissions
                .map((np) => `np[]=${encodeURIComponent(np)}`)
                .join("&"))
            .then(permissions => {
                setUserPermissions(prev => {
                    return {...prev, ...permissions}
                });
            });
    }
    return {checkPermissions};
}