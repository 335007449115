import * as React from "react";
import { createBrowserRouter } from "react-router-dom";
import App from "../App";
import ErrorPage from "pages/error/ErrorPage";
import LoginPage from "pages/login/LoginPage";
import LogoutPage from "pages/logout/LogoutPage";
import ChatPage from "pages/chat/ChatPage";
import EditUrlPage from "pages/editurl/EditUrl";
import AssistantDocuments from "pages/assistant/components/AssistantDocuments";
import ProfilePage from "pages/profile/ProfilePage";
import ResetPasswordPage from "pages/profile/ResetPasswordPage";
import HomePage from "pages/home/HomePage";
import { TrainingMessageDetailsPage } from "pages/training/TrainingMessageDetailsPage";

const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        errorElement: <ErrorPage />,
        children: [
            {
                path: "/",
                element: <HomePage />,
            },
            {
                path: "/chat",
                element: <ChatPage />,
            },
            {
                path: "/login",
                element: <LoginPage />,
            },
            {
                path: "/edit-url",
                element: <EditUrlPage />,
            },
            {
                path: "/profile",
                element: <ProfilePage />,
            },
            {
                path: "/logout",
                element: <LogoutPage />,
            },
            {
                path: "/assistant/:id/documents",
                element: <AssistantDocuments />,
            },
            {
                path: "/reset-password",
                element: <ResetPasswordPage />,
            },
            {
                path: "/training-message-details/:id",
                element: <TrainingMessageDetailsPage />,
            },
        ]
    }
]);

export default router;
