import React, {useEffect, useState} from 'react';
import {t} from "i18next";
import FilterText from "../../../components/filters/FilterText";
import FilterSelect from "../../../components/filters/FilterSelect";
import {Button, Table} from "react-bootstrap";
import {BsTrashFill} from "react-icons/bs";
import {IoMdAdd} from "react-icons/io";
import {IProfile} from "../../../interfaces/IProfile";
import IFile from "../../../interfaces/IFile";
import {Api} from "../../../services/api";

const hasUser = (user: IProfile, fileUsers: IProfile[]) => {
    const filtered = fileUsers.filter((fileUser: IProfile) => {
        return fileUser.id == user.id
    });
    return filtered.length > 0;
}

const StateChoices = {
    'true': <BsTrashFill size={24}/>,
    'false': <IoMdAdd size={24}/>,
}

interface UsersProps {
    file: IFile,
    showModal?: boolean
}

const Users = ({file, showModal}: UsersProps) => {
    const [allUsers, setAllUsers] = useState<IProfile[]>([]);
    const [fileUsers, setFileUsers] = useState<IProfile[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<IProfile[]>([]);
    const [filters, setFilters] = useState<any>({});

    const loadUsers = async (file: IFile) => {
        let response = await Api.get<IProfile[]>('file/users')
        setAllUsers(response);
        response = await Api.get<IProfile[]>('file/' + file.id + '/users')
        setFileUsers(response);
    };

    const toggleUser = async (user: IProfile) => {
        let url;
        let needConfirm = false;
        if (hasUser(user, fileUsers)) {
            url = 'file/' + file!.id + '/removeuser/' + user.id;
            needConfirm = true;
        } else {
            url = 'file/' + file!.id + '/adduser/' + user.id;
        }
        if (!needConfirm || window.confirm(t('file.users.confirmRemove'))) {
            const response = await Api.get<IProfile[]>(url)
            setFileUsers(response);
        }
    }

    useEffect(() => {
        if (file && showModal) {
            void loadUsers(file);
        }
    }, [showModal, file]);
    useEffect(() => {
        const filtered = allUsers.filter((user) => {
            let result = true;

            if (typeof filters.username !== 'undefined' && filters.username.length && user.username.toLowerCase().indexOf(filters.username.toLowerCase()) === -1) {
                result = false;
            }

            if (typeof filters.operations !== 'undefined' && filters.operations.length && filters.operations.length > 0) {
                const hasUsr = hasUser(user, fileUsers);
                if (
                    (hasUsr && !filters.operations.includes('true')) ||
                    (!hasUsr && !filters.operations.includes('false'))
                ) {
                    result = false;
                }
            }

            return result;
        });

        setFilteredUsers(filtered);
    }, [filters, allUsers]);

    return (
        <Table responsive="sm" hover className="align-middle">
            <thead>
            <tr>
                <th>
                    {t("file.rights.users.table.name")}
                    <FilterText filters={filters} setFilters={setFilters} field="username"
                                fieldLabel="file.rights.users.table.name"></FilterText>
                </th>
                <th className="text-end">
                    {t("file.operations")}
                    <FilterSelect field="operations" fieldLabel={t("file.operations")}
                                  choices={StateChoices} filters={filters} setFilters={setFilters}/>
                </th>
            </tr>
            </thead>
            <tbody>
            {filteredUsers.length === 0 && <tr>
                <td colSpan={2} className="text-center">{t('file.users.noUser')}</td>
            </tr>}
            {filteredUsers.map(user => <tr key={user.id}>
                <td>
                    <div className="d-flex justify-content-start align-items-center">
                        <div className="profile-image d-inline-flex me-2">
                            <img src={user.profile_image}/>
                        </div>
                        {user.username}
                    </div>
                </td>
                <td className="text-end">
                    {hasUser(user, fileUsers) ?
                        <Button onClick={() => toggleUser(user)} variant="outline-danger">
                            <BsTrashFill/>
                        </Button> :
                        <Button onClick={() => toggleUser(user)} variant="outline-secondary">
                            <IoMdAdd/>
                        </Button>
                    }
                </td>
            </tr>)}
            </tbody>
        </Table>
    );
};

export default Users;