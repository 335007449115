import React, {useEffect, useState} from 'react';
import {IGroup} from "../../../interfaces/IGroup";
import {IProfile} from "../../../interfaces/IProfile";
import {Api} from "../../../services/api";
import {t} from "i18next";
import {Button, Modal, OverlayTrigger, Table, Tooltip} from "react-bootstrap";
import {BsFillPeopleFill, BsTrashFill} from "react-icons/bs";
import {IoMdAdd} from "react-icons/io";
import PermissionCheck from "../../../components/permissioncheck/permissioncheck";
import FilterText from "../../../components/filters/FilterText";
import FilterSelect from "../../../components/filters/FilterSelect";


interface UsersProps {
    group: IGroup
}

const StateChoices = {
    'true': <BsTrashFill size={24}/>,
    'false': <IoMdAdd size={24}/>,
}

const hasUser = (user: IProfile, groupUsers: IProfile[]) => {
    const filtered = groupUsers.filter((groupUser: IProfile) => {
        return groupUser.id == user.id
    });
    return filtered.length > 0;
}

const Users = ({group}: UsersProps) => {
    const [showModal, setShowModal] = useState(false);
    const [groupUsers, setGroupUsers] = useState<IProfile[]>([]);
    const [allUsers, setAllUsers] = useState<IProfile[]>([]);
    const [filteredUsers, setFilteredUsers] = useState<IProfile[]>([]);
    const [filters, setFilters] = useState<any>({});

    const loadUsers = async (group: IGroup) => {
        let response = await Api.get<IProfile[]>('groups/users')
        setAllUsers(response);
        response = await Api.get<IProfile[]>('groups/' + group.id + '/users')
        setGroupUsers(response);
    };

    const toggleUser = async (user: IProfile) => {
        let url;
        let needConfirm = false;
        if (hasUser(user, groupUsers)) {
            url = 'groups/' + group!.id + '/removeuser/' + user.id;
            needConfirm = true;
        } else {
            url = 'groups/' + group!.id + '/adduser/' + user.id;
        }
        if (!needConfirm || window.confirm(t('group.users.confirmRemove'))) {
            const response = await Api.get<IProfile[]>(url)
            setGroupUsers(response);
        }
    }

    useEffect(() => {
        if (group && showModal) {
            void loadUsers(group);
        }
    }, [showModal, group]);

    useEffect(() => {
        const filtered = allUsers.filter((user) => {
            let result = true;

            if (typeof filters.username !== 'undefined' && filters.username.length && user.username.toLowerCase().indexOf(filters.username.toLowerCase()) === -1) {
                result = false;
            }

            if (typeof filters.operations !== 'undefined' && filters.operations.length && filters.operations.length > 0) {
                const hasUsr = hasUser(user, groupUsers);
                if (
                    (hasUsr && !filters.operations.includes('true')) ||
                    (!hasUsr && !filters.operations.includes('false'))
                ) {
                    result = false;
                }
            }

            return result;
        });

        setFilteredUsers(filtered);
    }, [filters, allUsers]);

    return (
        <PermissionCheck permission={['account.view_user']}>
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip-2">
                    <div>{t('group.users.iconTooltip')}</div>
                </Tooltip>}
            >
            <Button
                variant="outline-secondary"
                size="sm"
                onClick={e => {
                    setShowModal(true)
                }}
            >
                <BsFillPeopleFill/>
            </Button>
            </OverlayTrigger>
            <Modal show={showModal} onHide={() => setShowModal(false)} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>{t("group.users.modalTitle")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>

                    <div className="card shadow-sm mb-3 px-3 py-2">
                        <Table responsive="sm" hover className="align-middle">
                            <thead>
                            <tr>
                                <th>
                                    {t("group.users.table.name")}
                                    <FilterText filters={filters} setFilters={setFilters} field="username"
                                                fieldLabel="group.table.username"></FilterText>
                                </th>
                                <th className="text-end">
                                    {t("group.operations")}
                                    <FilterSelect field="operations" fieldLabel={t("group.operations")} choices={StateChoices} filters={filters} setFilters={setFilters}/>
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            {filteredUsers.length === 0 && <tr>
                                <td colSpan={2} className="text-center">{t('group.users.noUser')}</td>
                            </tr>}
                            {filteredUsers.map(user => <tr key={user.id}>
                                <td>
                                    <div className="d-flex justify-content-start align-items-center">
                                        <div className="profile-image d-inline-flex me-2">
                                            <img src={user.profile_image}/>
                                        </div>
                                        {user.username}
                                    </div>
                                </td>
                                <td className="text-end">
                                    {hasUser(user, groupUsers) ?
                                        <Button onClick={() => toggleUser(user)} variant="outline-danger">
                                            <BsTrashFill/>
                                        </Button> :
                                        <Button onClick={() => toggleUser(user)} variant="outline-secondary">
                                            <IoMdAdd/>
                                        </Button>
                                    }
                                </td>
                            </tr>)}
                            </tbody>
                        </Table>
                    </div>
                </Modal.Body>
            </Modal>
        </PermissionCheck>
    )
};

export default Users;