import Alert from 'react-bootstrap/Alert';
import {Container} from "react-bootstrap";
import {PiWarningBold} from 'react-icons/pi';
import {Navigate} from "react-router-dom";
import {useContext} from "react";
import DataContext from "../../context/DataContext";

const PermissionCheck = ({permission, message, force, children}: {
    permission: string | string[],
    message?: string,
    force?:boolean,
    children: any
}) => {
    const {userPermissions} = useContext(DataContext);
    const token = localStorage.getItem('token');
    if (!token) {
        return <Navigate to="/login" replace={true}/>;
    }

    const permResult = (children: any) => {

        const permissionsToCheck = Array.isArray(permission) ? permission : [permission];
        if (force === true || permissionsToCheck.some(perm => userPermissions[perm] === true)) {
            return children;
        }
        return message?.length ? <Container style={{"marginTop": "25px"}}>
            <Alert variant="warning" className="d-flex align-items-center">
                <PiWarningBold size={22}/><span className="ps-3">{message}</span>
            </Alert>
        </Container> : "";
    }

    return <>{permResult(children)}</>
}

export default PermissionCheck;