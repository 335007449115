import React, {useEffect, useState} from 'react';
import IFile from "interfaces/IFile";
import {Button, Modal, Tab, Table, Tabs, Tooltip} from "react-bootstrap";
import {t} from "i18next";
import {BsFillPeopleFill, BsTrashFill} from "react-icons/bs";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import FilterText from "components/filters/FilterText";
import FilterSelect from "components/filters/FilterSelect";
import {IoMdAdd} from "react-icons/io";
import {IProfile} from "interfaces/IProfile";
import {Api} from "services/api";
import Assistants from "../../assistant/Assistants";
import Users from "./Users";
import Groups from "./Groups";

interface RightsProps {
    file: IFile
}

const Rights = ({file}: RightsProps) => {
    const [showModal, setShowModal] = useState(false);
    const [activeKey, setActiveKey] = useState<string>('groups');

    return (
        <>
            <OverlayTrigger
                placement="bottom"
                overlay={<Tooltip id="button-tooltip-2">
                    <div>{t('file.rights.tooltip')}</div>
                </Tooltip>}
            >
                <Button size="sm"
                        variant="outline-secondary"
                        onClick={e => setShowModal(true)}>
                    <BsFillPeopleFill size={18}/>
                </Button>
            </OverlayTrigger>
            <Modal size="lg"
                   show={showModal}
                   onHide={() => {setShowModal(false)}}
                   centered>
                <Modal.Header closeButton>
                    <Modal.Title>
                        {t("file.rights.modalTitle")}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Tabs
                        id="controlled-tabs-list"
                        className="mt-4 px-5"
                        activeKey={activeKey}
                        onSelect={(k) => setActiveKey(k ?? 'groups')}
                    >
                        <Tab eventKey="groups" title={
                            <div className="d-inline-flex align-items-center justify-content-center">
                                <span>{t('file.rights.groups.tabkey')}</span>
                            </div>
                        } className="px-2">
                            {activeKey === 'groups' && <Groups file={file} showModal={showModal} />}
                        </Tab>

                        <Tab eventKey="users" title={
                            <div className="d-inline-flex align-items-center justify-content-center">
                                <span>{t('file.rights.users.tabkey')}</span>
                            </div>
                        } className="px-2">
                            {activeKey === 'users' && <Users file={file} showModal={showModal} />}
                        </Tab>

                    </Tabs>

                </Modal.Body>
            </Modal>
        </>
    );
};

export default Rights;