import { useEffect, useState, useCallback } from "react";
import { t, use } from "i18next";
import toast from "react-hot-toast";
import { Api } from "../../../services/api";
import { useTrainingMessage } from "../TrainingMessageDetailsPage";

interface INote {
    id: number;
    text: string;
    created_at: string;
    user: {
        username: string;
    };
}

const Notest = () => {
    const [notes, setNotes] = useState<INote[]>([]);
    const [textArea, setTextArea] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const trainingMessage = useTrainingMessage();

    const fetchNotes = useCallback(async () => {
        if (trainingMessage?.id) {
            await Api.get<any>(`training-message/list-notes/${trainingMessage?.id}`).then((response) => {
                setNotes(response);
            }).catch((error) => {
                console.error(error)
            });
        }
    }, [trainingMessage?.id]);

    useEffect(() => {
        fetchNotes();
    }, [trainingMessage?.id, fetchNotes]);

    const handleSubmit = async () => {
        if (textArea.trim().length === 0) {
            toast.error(t("training.please_provide_a_message"), {
                position: "top-center"
            });
            return;
        }

        let data = {
            method: 'POST',
            headers: {
                "Content-Type": "application/json",
                "Accept": "application/json",
            },
            body: JSON.stringify({
                parent: trainingMessage?.id,
                text: textArea,
            })
        };

        await Api.raw<INote>(`training-message/send-note`, data).then((response) => {
            if (response.status === 200) {
                setTextArea('');
                toast.success(t("training.note_saved"), {
                    position: "top-center"
                });

                // Frissítjük a jegyzetlistát
                fetchNotes();
            } else {
                toast.error(t("training.error_occurred_try_again"), {
                    position: "top-center"
                });
            }
        }).catch((error) => {
            console.error(error)
        });
    }

    const deleteNote = async (noteId: number) => {
        setIsLoading(true);
        await Api.delete<any>(`training-message/delete-note/${noteId}`).then((response) => {
            toast.success(t("training.note_deleted"), {
                position: "top-center"
            });
            fetchNotes();
        }).catch((error) => {
            console.error(error)
        }).finally(() => {
            setIsLoading(false);
        });
    }

    return (
        <>
            <div className="mb-3">
                {notes && notes.length > 0 && notes.map((note: INote, index: number) => (
                    <div key={index} className="card mb-2 border-dark shadow-sm">
                        <div className="card-body">
                            <div className="d-flex justify-content-between align-items-center">
                                <div><b>@{note.user.username}</b> - {new Date(note.created_at).toISOString().slice(0, 16).replace('T', ' ')}</div>
                                <button
                                    className="btn btn-secondary btn-sm"
                                    onClick={() => {
                                        if (window.confirm(t("training.confirm_note_deletion"))) {
                                            deleteNote(note.id);
                                        }
                                    }}
                                >
                                    {isLoading ? <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> : t("training.delete")}
                                </button>
                            </div>
                            <div className="py-2">{note.text}</div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="card shadow-sm">
                <div className="card-body">
                    <textarea className="form-control" placeholder={t("training.message_text")} onChange={(e) => setTextArea(e.target.value)} value={textArea}></textarea>
                </div>
                {trainingMessage?.id && <div className="card-footer text-center">
                    <button className="btn btn-primary px-4" onClick={handleSubmit}>Küldés</button>
                </div>}
            </div>
        </>
    );
}

export default Notest;